.py-8 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
}
@media (min-width: 576px) {
    .py-sm-8 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important;
    }
}
@media (min-width: 768px) {
    .py-md-8 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important;
    }
}
@media (min-width: 992px) {
    .py-lg-8 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important;
    }
}
@media (min-width: 1200px) {
    .py-xl-8 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important;
    }
}
@media (min-width: 1400px) {
    .py-xxl-8 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important;
    }
}
.bsb-timeline-5 {
    --bsb-tl-color: #e2e42b;
    --bsb-tl-circle-color: var(--bs-white);
    --bsb-tl-circle-border-color: #e2e42b;
    --bsb-tl-circle-shadow-color: #e2e42b;
    --bsb-tl-circle-size: 32px;
    --bsb-tl-circle-offset: 16px;
    --bsb-tl-circle-border-size: 2px;
}
.bsb-timeline-5 .timeline {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.bsb-timeline-5 .timeline:after {
    background-color: var(--bsb-tl-color);
    bottom: 0;
    content: "";
    left: 0;
    margin-left: -1px;
    position: absolute;
    top: 0;
    width: 2px;
}
.bsb-timeline-5 .timeline > .timeline-item {
    margin: 0;
    padding: 0;
    position: relative;
}
.bsb-timeline-5 .timeline > .timeline-item .timeline-icon {
    align-items: center;
    background-color: #e2e42b;
    border: var(--bsb-tl-circle-border-size) solid var(--bsb-tl-circle-border-color);
    border-radius: 50%;
    box-shadow: 3px 3px 0 rgba(var(--bsb-tl-circle-shadow-color), 0.4);
    display: flex;
    height: var(--bsb-tl-circle-size);
    justify-content: center;
    left: calc(var(--bsb-tl-circle-offset) * -1);
    position: absolute;
    top: 0;
    width: var(--bsb-tl-circle-size);
    z-index: 1;
}
.bsb-timeline-5 .timeline > .timeline-item .timeline-body {
    margin: 0;
    padding: 0;
    position: relative;
}
.bsb-timeline-5 .timeline > .timeline-item .timeline-content {
    padding: 0 0 2.5rem 2.5rem;
}
@media (min-width: 768px) {
    .bsb-timeline-5 .timeline > .timeline-item .timeline-content {
        padding-bottom: 3rem;
    }
}
.bsb-timeline-5 .timeline > .timeline-item:last-child .timeline-content {
    padding-bottom: 0;
}
.card .card-header {
    min-height: 30px;
}
.bi-check-lg::before {
    color: #fff;
}
