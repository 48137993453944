@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 40px;
    height: 40px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #53119b; /* Black */
    border-radius: 50%;
    animation: spinner 0.5s linear infinite;
}

.loading-spinner-admin {
    width: 40px;
    height: 40px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #00a3ff; /* Black */
    border-radius: 50%;
    animation: spinner 0.5s linear infinite;
}

.loading-spinner-center-align {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.loading-spinner-center-align-srp {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 15vh;
}
.admin-loading {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    .loading-spinner {
        border-top: 5px solid #00a3ff !important;
    }
}
.align-loading {
    display: flex !important;
    justify-content: center;
    align-items: center;
    .loading-spinner {
        border-top: 5px solid #00a3ff !important;
    }
}
