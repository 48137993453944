#template-admin-inventory .table-responsive {
    overflow-x: visible;
}

.fas,
.far,
.ki-duotone {
    cursor: pointer;
}

// DraggableImages.scss

#image-container {
    display: flex;
    flex-wrap: wrap;

    .draggable-image {
        width: 20%;
        margin: 10px;
        cursor: grab;
        position: absolute;

        &:active {
            cursor: grabbing;
        }
    }
}

#template-admin-inventory input:disabled {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-gray-700) !important;
}

.form-floating {
    padding-left: 0px !important;
    // margin-left: calc(var(--bs-gutter-x) * 0.5);

    &.dollar-wrapper {
        label {
            margin-left: 2.25rem !important;
        }
        input {
            padding-top: 1.85rem;
            padding-bottom: 0.625rem;
        }
    }
}

.img-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;

    .image {
        width: 75px;
        margin-right: 5px;
        height: 75px;
        margin-bottom: 8px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
        span {
            position: absolute;
            top: -2px;
            right: 9px;
            font-size: 20px;
            cursor: pointer;
        }
    }
    .input,
    .drag-area {
        &.on-drop,
        &.dragover,
        &.visible {
            display: none;
        }
    }

    .delete {
        z-index: 99;
        color: #0086fe;
    }
}

.img-drop-container {
    width: 100%;
    height: 200px;
    border: 2px dashed #ced4da;
    border-radius: 10px;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    align-content: space-around;
    justify-content: start;
    flex-direction: row;

    svg {
        margin-left: 2rem;
    }

    input {
        display: none;
    }

    &:hover {
        border-color: #4f20f5;
    }

    .image {
        width: 75px;
        margin-right: 5px;
        height: 75px;
        margin-bottom: 8px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
        span {
            position: absolute;
            top: -2px;
            right: 9px;
            font-size: 20px;
            cursor: pointer;
        }
    }
    .drop-title {
        text-align: left;
        font-size: 16px;
        .browse-text {
            color: #4f20f5;
            font-weight: 500;
        }
    }
    .drop-text {
        font-size: 12px;
        color: #9d9ca9;
    }
}
.upload-btn {
    background-color: #4f20f5 !important;
    color: #fff !important;
}
.notify-btn {
    color: #fff !important;
    background-color: #4f20f5 !important;
}

.notify-btn-share {
    color: #fff !important;
    background-color: #2a0a4d !important;
}

.button-div {
    button {
        border-radius: 26px;
    }
    .back-btn {
        border: 1px solid #c2c2cf !important;
        color: #4f20f5;
        &:active {
            color: #4f20f5 !important;
        }
    }
    .listing-btn {
        padding: 0.75em !important;
        min-width: 10em !important;
        @media (max-width: 360px) {
            min-width: 7em !important;
        }
    }
}
.button-div-share {
    button {
        border-radius: 26px;
    }
    .back-btn-share {
        border: 1px solid #c2c2cf !important;
        color: #2a0a4d;
    }
}
.image-list-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;

    @media (max-width: 767px) {
        grid-template-columns: repeat(3, 1fr);
    }
    .dnd-image-container {
        margin: 5px;
        cursor: grab;
        min-width: 0;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        transition: border 0.3s ease-in-out;

        &:hover {
            border: 2px solid #4f20f5;
        }

        .image {
            width: 100%;
            height: auto;
            display: block;
        }
        @media (max-width: 525px) {
            margin: 0px;
        }

        .footer-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 30px;
            z-index: 30;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            &.visible {
                opacity: 1;
            }

            .icon {
                cursor: pointer;
            }
            @media (max-width: 525px) {
                height: 18px;
            }

            .set-hero-image {
                display: flex;
                cursor: pointer;
                color: #4f20f5;

                .ki-picture {
                    margin-bottom: 0;
                }
                @media (max-width: 525px) {
                    font-size: 0.6rem;
                }
            }

            .delete-icon {
                cursor: pointer;
            }
        }

        .purple-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 30px;
            z-index: 20;
            background-color: #4f20f5;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 525px) {
                height: 18px;
            }
            p {
                color: #fff;
                margin: 0;
            }
            @media (max-width: 525px) {
                font-size: 0.75rem;
            }
        }

        .hover-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0);
            transition: background 0.3s ease-in-out;
            opacity: 0;
        }
    }
}
.aside.aside-default {
    width: 240px !important;
}
.history-container {
    height: 200px;
    overflow-y: auto;
}

.inv-page {
    .card-title {
        .search-container {
            // .form-floating {
            //     width: 225px;
            // }
            @media (max-width: 525px) {
                width: 100%;
                display: block !important;
                input[data-kt-ecommerce-product-filter] {
                    min-width: 100% !important;
                    width: 100% !important;
                }
            }
            button {
                margin: 0px 7px;
                @media (max-width: 525px) {
                    margin: 0px 0px;
                    width: 100%;
                }
            }
        }
    }

    .status-dropdown {
        max-width: 125px;
        @media (max-width: 525px) {
            min-width: 100% !important;
        }
    }

    .datepicker-container {
        @media (max-width: 525px) {
            width: 100% !important;
            .react-datepicker-wrapper {
                width: 100% !important;
            }
        }
    }
}
