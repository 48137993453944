.min-height-100 {
  min-height: 80vh;

  .default-page-image {
    width: 100%;
    height: auto;
  }
  .card404 {
    //background-image: url("../../../../public/media/404Image.gif");
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: multiply;
    width: 560px;
    height: 550px;
    margin-bottom: 4em;

    h1 {
      font-size: 50px;
      margin-bottom: 0 !important;
    }

    p {
      color: gray;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    hr {
      border: 1px solid rgba(0, 0, 0, 0.175);
      width: 100%;
      margin-left: unset;
      margin-right: unset;
      opacity: 0.75;
    }

    span {
      a {
        text-decoration: none;
      }
    }
  }
}
