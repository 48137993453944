@import "./global_fonts.scss";
.error {
    min-height: 21rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: gainsboro;
    @media screen and (max-width: 575px) {
        min-height: 37rem;
    }
    @media screen and (min-width: 576px) and (max-width: 991px) {
        min-height: 38rem;
    }

.error_btn{
    margin-top: 30px;
}
}

