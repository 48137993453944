@import "./variable.scss";
@import "./global_fonts.scss";
@import "../fonts/stylesheet.css";

.here-for-you-wrapper {
    h2 {
        font-weight: 900;
    }
    .main-column {
        background-image: url("../../../public/media/get-your-price-bg.png");
        background-position: bottom;
        background-size: cover;
        border-radius: 20px;
        padding: 25px 30px;
        margin: 15px 0;
        min-height: 230px;
        h2 {
            font-size: 24px;
        }
        p {
            color: #1a1919;
            font-size: 17px;
            font-weight: 400;
            line-height: 27.32px;
            text-align: left;
        }
        .custom_btn {
            background: unset;
            justify-content: left;
            padding: 0;
            color: #53119b;
            svg {
                left: 5rem;
                top: 7px;
            }
            svg path {
                fill: #53119b;
            }
        }
        .custom_btn:focus {
            background-color: transparent !important;
            outline: none;
            border: none;
        }
    }

    .main-row > .col-md-6:nth-child(2) > .main-column,
    .main-row > .col-md-6:nth-child(3) > .main-column,
    .main-row > .col-md-6:nth-child(6) > .main-column {
        background-color: #e2e42b;
        background-image: unset;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .row.main-column.text-start svg {
        width: 52px;
    }
    .main-column {
        gap: 15px;
        padding: 15px 0px 15px 20px !important;
        min-height: 400px !important;
    }
}
